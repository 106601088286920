import React, { Component } from 'react';

import { makeStyles } from "@material-ui/core/styles";

import '../About.css';

import Speed from "@material-ui/icons/SpeedRounded";
import Money from "@material-ui/icons/MonetizationOnOutlined";
import Flex from "@material-ui/icons/GroupOutlined";
import Handling from "@material-ui/icons/TransformOutlined";

// core components
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem.js";
import InfoArea from "../material-kit-react-main/InfoArea/InfoArea.js";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/productStyle";
const useStyles = makeStyles(styles);

export default function About() {
    const classes = useStyles();
        return (
            <div className={classes.section}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>What is FTL?</h2>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.description}>Full Truckload (FTL) is a mode of freight for larger shipments that typically occupy more than half and up to the full capacity
                        of a 48’ or 53’ trailer. This method is commonly used when shippers decide they have enough items to fill a truck, want their shipment in a trailer by itself,
                        the freight is time-sensitive or the shipper decides it’s more cost-effective than other options.
                        </p>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>Why FTL?</h2>
                    </GridItem>


                    <GridItem xs={12} sm={12} md={6}>
                        <InfoArea
                            title="Fast Transit"
                            description="Goods that are shipped via full truckload generally arrive at their destination quicker than goods that are shipped via LTL’s hub-and-spoke model."
                            icon={Speed}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <InfoArea
                            title="Reduced Rates"
                            description="If shipments are large enough to require the entire use of a trailer’s space, it could be more cost effective than booking multiple LTL shipments."
                            icon={Money}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <InfoArea
                            title="Flexibility"
                            description="Our team will tailor lanes and pricing that work best for your business case."
                            icon={Flex}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <InfoArea
                            title="Less Handling"
                            description="Full truckload shipments are generally less susceptible to damages as they are handled less times than LTL shipments."
                            icon={Handling}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
}