import React, { Component } from 'react';

import GridContainer from "../components/material-kit-react-main/Grid/GridContainer";
import GridItem from "../components/material-kit-react-main/Grid/GridItem";
import CustomInput from "../components/material-kit-react-main/CustomInput/CustomInput";
import { makeStyles } from "@material-ui/core/styles";

import './Home.css';
import Card from './material-kit-react-main/Card/Card';
import CardBody from './material-kit-react-main/Card/CardBody';
import CardHeader from './material-kit-react-main/Card/CardHeader';
import CardFooter from './material-kit-react-main/Card/CardFooter';
import NavPills from './material-kit-react-main/NavPills/NavPills';
import InputAdornment from "@material-ui/core/InputAdornment";

//React router
import Link from "react-router-dom/Link";

//icons
import Location from "@material-ui/icons/PinDropRounded";
import Plane from "@material-ui/icons/FlightTakeoffRounded";
import Truck from "@material-ui/icons/LocalShippingRounded";
import Globe from "@material-ui/icons/PublicRounded";

import CustomTabs from "../components/material-kit-react-main/CustomTabs/CustomTabs";

//images
import aggregatesImage from '../assets/img/Buendia Enterprises-33.jpg'
import endDumpImage from '../assets/img/End-dump.jpg'
import highwayImage from '../assets/img/highway_1.jpeg'
import stockMarketImage from '../assets/img/stockMktImage.jpeg'
import highway2Image from '../assets/img/highway2.jpeg'
//jquery
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

//Bootstrap - html
import '../assets/bootstrap/dist/css/bootstrap.css';

// core components
import Button from "./material-kit-react-main/CustomButtons/Button";

import { Container } from 'reactstrap';


import imagesStyles from "../assets/jss/material-kit-react/imagesStyles";
import { cardTitle } from "../assets/jss/material-kit-react";
const styles = {
    imagesStyles,
    cardTitle,
};
const useStyles = makeStyles(styles);


class Home extends Component {
    static displayName = Home.name;

    _ajaxUSPSApi(valid_zip, $elem) {
        $.ajax({
            url: "https://production.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID = \"852BUEND6973\"><ZipCode ID=\"0\"><Zip5>"+valid_zip+"</Zip5></ZipCode></CityStateLookupRequest>",
            dataType: "xml",
            success: function (response) {
                var city,state;
                $(response).find("City").each(function () {
                    city = $(this).text();
                });
                $(response).find("State").each(function () {
                    state = $(this).text();
                });
                if ($($elem).attr('id') == "pu_locale_input") $("#pu_locale").text(city + ", " + state);
                else $("#do_locale").text(city + ", " + state);
            },
            error: function (error) {
                console.log('ajax failure');
            }
        });
    }


    inputZipAutoComplete = (event) => {
        if (event.target.value >= 99999 || event.target.value <= 10000) {
            // invalid zip param - no search
            $(event.target).removeClass('green');
            $(event.target).addClass('red');
        }
        else {
            // valid zip param
            var suggestion = this._ajaxUSPSApi(event.target.value, event.target);
            $(event.target).removeClass('red');
            $(event.target).addClass('green');
        }
        
    }

    render() {
        const classes = this.props.classes;
        return (
          <GridContainer>
              {/* BG CONFIG */}
              <GridItem><div className="jss95_bei"><div className="home-secondary-lbl"><h2>WELCOME TO THE FAMILY</h2><br/><h5>Proudly based in Houston, Texas</h5></div></div></GridItem>
              <GridItem><div className="bg-pattern"></div></GridItem>
              <GridItem><div className="bg-pattern"></div></GridItem>
              <GridItem><div className="bg-pattern"></div></GridItem>
              {/*  L1  */}
              <GridItem>
                  <Container>
                      <GridContainer>
                          <GridItem>
                              <div className="jss6_bei jss5_bei">
                                    <Card className="quote_card_bei" style={{ width: "100%" }}>
                                        <CardHeader><div className="jss01_lbl">YOUR NEW 3PL SOLUTION</div><hr/></CardHeader>
                                      <CardBody>
                                          <GridContainer spacing={1}>
                                              <GridItem xs={12}>
                                                  {/*  TAG AND SELECTORS  */}
                                                    <GridContainer className="jss6_ig_offset_bei" spacing={2}>
                                                      <GridItem xs={12} md={4}><div className="jss6_lbl">REQUEST A QUOTE</div></GridItem>
                                                      <GridItem xs={12} md={8}>
                                                          <NavPills alignCenter color="primary" tabs={[
                                                              {
                                                                  tabButton: "FTL",
                                                                  tabIcon: Truck,
                                                                  tabContent: (<span><b>Full Truck Load</b>: Fill most to all of an entire truck and tend to be much larger, often weighing 20,000 pounds or more.</span>)
                                                              },
                                                              {
                                                                  tabButton: "LTL",
                                                                  tabIcon: Truck,
                                                                  tabContent: (<span><b>Less-than Truck Load</b>: Smaller shipments typically ranging from 100 to 5,000 pounds. These smaller shipments will not fill an entire truck, leaving space for other small shipments </span>)
                                                              },
                                                              {
                                                                  tabButton: "OTHER",
                                                                  tabIcon: Plane,
                                                                  tabContent: (<span><b>Other</b>: FTL or LTL shipments with special requirments such as Expedited Air, Intermodal, or International</span>)
                                                              }
                                                          ]} />
                                                      </GridItem>
                                                  </GridContainer>
                                              </GridItem>
                                                <GridItem></GridItem>{/*<hr>*/}
                                              <GridItem xs={12}>
                                                  {/*  FORM  */}
                                                  <GridContainer spacing={2}>
                                                      <GridItem xs={5}>

                                                      </GridItem>
                                                      <GridItem xs={3}>
                                                          
                                                      </GridItem>   
                                                        <GridItem xs={2}><Link /*state?? */><Button onClick={() => { window.open('https://quote.beisynergy.com/freight', '_blank');}} className="btn_q_submit" color="primary" size="sm">Continue &nbsp;<FontAwesomeIcon icon={faArrowRight} /></Button></Link></GridItem>
                                                  </GridContainer>                 
                                              </GridItem>
                                              {/* City Confirmations */}
                                              <GridItem>
                                                  <GridContainer spacing={2}>
                                                      <GridItem xs={5}>
                                                          <div className="city_confirm_lbl" id="pu_locale"></div>
                                                      </GridItem>
                                                      <GridItem xs={5}>
                                                          <div className="city_confirm_lbl" id="do_locale"></div>
                                                      </GridItem>
                                                      <GridItem xs={2}></GridItem>
                                                  </GridContainer>
                                                </GridItem>
                                          </GridContainer>
                                      </CardBody>
                                  </Card>
                              </div>
                          </GridItem>
                      </GridContainer>
                  </Container>
              </GridItem>
              <GridItem><br/></GridItem>       
              {/*  L2  */}
              <GridItem>
                  <Container className="justify-content-center w-100">
                      <GridContainer alignItems="center" justifyContent="center" className="justify-content-center w-100 ml-2" spacing={2}>
                          <GridItem xs={12} sm={12} md={6}>
                              <Card style={{ width: "100%"}}>
                                  <img
                                      style={{ height: "50vh", width: "100%", objectFit:"cover", objectPosition:"center",display: "block" }}
                                      className={classes.imgCardTop}
                                      src={aggregatesImage}
                                      alt="Card-img-cap"
                                  />
                                  <CardBody>
                                      <h4 className={classes.cardTitle}>Aggregates Broker</h4>
                                      <p>Buendia Enterprises has operated as a premier Aggregates broker for Sand, Gravel, Limestone, and more in the Texas-Triangle region since 2015.</p>
                                      <a href="/aggregates/info"><Button color="primary">Learn More</Button></a>
                                  </CardBody>
                              </Card>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                              <Card style={{ width: "100%"}}>
                                  <img
                                      style={{ height: "50vh", width: "100%", objectFit:"cover", objectPosition:"center",display: "block" }}
                                      className={classes.imgCardTop}
                                      src={endDumpImage}
                                      alt="Card-img-cap"
                                  />
                                  <CardBody>
                                      <h4 className={classes.cardTitle}>Hiring Owner-Operators</h4>
                                      <p>We are currently hiring End-Dump Owner Operators - choose to work one of our high-dollar lanes!  Be home nights and weekends.</p>
                                      <a href="/contact"><Button color="primary">Learn More</Button></a>
                                  </CardBody>
                              </Card>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={4}>
                              <Card style={{ width: "100%"}}>
                                  <img
                                      style={{ height: "50vh", width: "100%", objectFit: "cover", objectPosition: "center", display: "block" }}
                                      className={classes.imgCardTop}
                                      src={stockMarketImage}
                                  />
                                  <CardBody>
                                      <h4 className={classes.cardTitle}>Competitive Rates</h4>
                                      <p>We consistently analyze our rates to ensure our clients recieve the best deals available in the market.</p>
                                        <a href="/freight/home"><Button className="w-100" color="primary">Learn More</Button></a>
                                  </CardBody>
                              </Card>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={4}>
                              <Card style={{ width: "100%"}}>
                                  <img
                                      style={{ height: "50vh", width: "100%", objectFit: "cover", objectPosition: "center", display: "block" }}
                                      className={classes.imgCardTop}
                                      src={highwayImage}
                                  />
                                  <CardBody>
                                      <h4 className={classes.cardTitle}>Drive with Us</h4>
                                      <p>Profitable opportunities available for Carriers and Owner Operators who operate Flatbeds, Vans, End-Dumps, and more.</p>
                                      <a href="/contact"><Button className="w-100" color="primary">Contact Us</Button></a>
                                  </CardBody>
                              </Card>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={4}>
                              <Card style={{ width: "100%"}}>
                                  <img
                                      style={{ height: "50vh", width: "100%", objectFit: "cover", objectPosition: "center", display: "block" }}
                                      className={classes.imgCardTop}
                                      src={highway2Image}
                                  />
                                  <CardBody>
                                      <h4 className={classes.cardTitle}>One Solution</h4>
                                      <p>Your one stop shop for all freight logistics needs from occasional small packages to high-volume cargo movements.</p>
                                      <a href="/freight/home"><Button className="w-100" color="primary">Learn More</Button></a>
                                  </CardBody>
                              </Card>
                          </GridItem>
                      </GridContainer>
                  </Container>
              </GridItem>
              {/*  L3  */}
              <GridItem></GridItem>
          </GridContainer>
    );
  }
}

export default () => {
    const classes = useStyles();
    return (
        <Home classes={classes} />
    )
}
