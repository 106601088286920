import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import Header from '../components/material-kit-react-main/Header/Header';
import HeaderLinks from '../components/material-kit-react-main/Header/HeaderLinks';

//images
import logo from "../assets/img/brands/bei_transparent.png";

import { Link } from 'react-router-dom';
import './NavMenuMain.css';


// core components
import Button from "./material-kit-react-main/CustomButtons/Button";

export class NavMenuMain extends Component {
    static displayName = NavMenuMain.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
<Header className="bei-navbar-secondary" color="transparent" brand={<a href="/" ><img height="80px;" src={logo} /></a>} leftLinks={<HeaderLinks />}></Header>
            </header>
        );
    }
}
