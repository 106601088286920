import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// @material-ui/icons
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
import Pin from "@material-ui/icons/LocationOn";

// @material-ui/icons

// core components
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem";
import InfoArea from "../material-kit-react-main/InfoArea/InfoArea";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/workStyle";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
      <div className={classes.section}>
        <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={8}>
                <h2 className={classes.title}>WE ARE HIRING!</h2>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={8}>
                  <h3 className={classes.description}>Financial Management Analyst (Full-time)</h3>
                  <p className={classes.textJob}>Duties: 
                      <ul>
                          <li>Analyze financial or operational performance of company to identify and recommend
                  remedies and increase internal efficiency.</li>
                          <li>Conduct financial analyses related to investments in concrete aggregates material
                          acquisition and inventory.</li>
                          <li>Employ financial models to develop solutions to financial problems and to assess the
                          financial or capital impact of transactions, new investment endeavors, and the potential of
                  new offerings.</li>
                          <li>Evaluate and compare the relative quality of various securities in the aggregates industry.</li>
                          <li>Evaluate capital needs of clients and assess market conditions to inform structuring of
                  financial packages.</li>
                          <li>Inform investment decisions by analyzing financial information to forecast business,
                  industry, or economic conditions for potential growth.</li>
                          <li>Research and interpret data on price, yield, stability, future investment-risk trends,
                  economic influences, and other factors affecting offered materials, inventory, and services.</li>
                          <li>Monitor developments in the fields of materials acquisition, industrial technology,
                  business, finance, and economic theory by analyzing information.</li>
                          <li>Perform securities valuation and pricing for materials and offered services. Prepare plans
                  of action for investment, using financial analyses.</li>
                          <li>Recommend investments and proposed investment timing to company and negotiate and
                  purchase investments for company as the financial representative.</li>
                          <li>Conduct comprehensive assessments of current business financial procedures and identify
                  areas of improvement to enhance operations and investments.</li>
                          <li>Conduct financial risk analyses and recommend automation services based on findings to
                  improve business efficiency.</li>
                          <li>Conduct internal evaluations on freight brokerage and import/export operations in
                              comparison to finances, and report to management with international logistic route systems
for review and consideration.</li>

                      </ul>
                    </p>
                  <p className={classes.textJob}>Requirements: Bachelor&#39;s degree in Finance; 5 years progressively responsible experience in logistics, and automation. Work will
                  be performed at Buendia Enterprises Inc. in Magnolia, TX.  Email resume to admin@buendiainc.com to apply.

Location of Buendia Enterprises Inc., 32731 Egypt Lane, Suite 402, Magnolia, Texas 77354</p>
              </GridItem>
          </GridContainer>
          <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={8}>
                  <h3 className={classes.description}>End Dump Owner Operators</h3>
                  <p className={classes.textJob}> <a href="/carriers/end-dumps"><b>Click here</b></a> to learn more</p>
              </GridItem>
          </GridContainer>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contact our Team</h2>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={4} md={4}>
            <InfoArea 
                title="+1(281)-789-7197"
                description="Available during normal business hours"
                icon={Phone}
                iconColor="primary"
                vertical
            />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
            <InfoArea 
                title="Magnolia, TX"
                description="32731 Egypt Ln Ste. 402"
                icon={Pin}
                iconColor="primary"
                vertical
            />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
            <InfoArea 
                title="freight@buendiainc.com"
                description="Available 24/7"
                icon={Email}
                iconColor="primary"
                vertical
            />
        </GridItem>
    </GridContainer>

    </div>
  );
}
