import React, { Component } from 'react';
import LandingPageInv from './../LandingPageInv';
//import { Helmet } from "react-helmet";

//css
import './../Contact.css';


export class Inventory extends Component {
    static displayName = Inventory.name;

    render() {
        return (
            <div>
                <LandingPageInv className="bei_landingpage" />
            </div>
        );
    }
}
