import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem";
import CustomInput from "../material-kit-react-main/CustomInput/CustomInput";
import Button from "../material-kit-react-main/CustomButtons/Button";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/workStyle";

const useStyles = makeStyles(styles);

export default function WorkSectionFTL() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Request a Quote</h2>
            <form>
                <GridContainer alignItems="center" direction="column">
                    <Button href="https://quote.beisynergy.com/freight" color="primary">Get a Quote</Button>
                </GridContainer>
            </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
