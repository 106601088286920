import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Speed from "@material-ui/icons/SpeedRounded";
import Money from "@material-ui/icons/MonetizationOnOutlined";
import Flex from "@material-ui/icons/GroupOutlined";
import Handling from "@material-ui/icons/TransformOutlined";

//images
import RM from '../../assets/img/readymix.jpg';
import Landscaping from '../../assets/img/landscaping.jpg';
import Precast from '../../assets/img/precast.jpg';
import RC from '../../assets/img/road construction.jpg';
import LandDev from '../../assets/img/gravelpad (2).jpg';
import Other from '../../assets/img/dump (2).jpg';


// core components
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem";
import InfoArea from "../material-kit-react-main/InfoArea/InfoArea.js";
import Parallax from "../material-kit-react-main/Parallax/Parallax";

import styles from "../../assets/jss/material-kit-react/views/landingPage";

// Sections for this page

import '../Layout.css';
import './Operations.css'

//icons

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Operations(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (
        <div>
            <Parallax filter className="set_bg_agg">
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem className="set_ta_center" xs={12} sm={12} md={6}>
                            <h1 className={classes.title}>We are BEI</h1>
                            <br />
                            <h3 className={classes.subtitle}>Bulk aggregates for all commercial purposes</h3>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className="main_container">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h2 className="main_title">How it works</h2>
                            </GridItem>
                            <GridItem className="paddbot" xs={12} sm={12} md={12}>
                                <p className={classes.description}>We source and deliver material in 25 ton incremenets via contracted end-dump trucks to your
                                    commercial operations.  Our <a href="/aggregates/inventory"><b>available products</b></a> include various limestone, gravel, sand,
                                    crushed concrete, bull rock, specialty landscaping, and DOT approved materials.
                            </p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className="col col-operations">
                                    <div className="row">
                                        <img className="sample_img" src={RM} />
                                    </div>
                                    <div className="row justify-content-center">
                                        <h3 className="w-100 industry_heading">Ready-Mix Operations</h3>
                                    </div>
                                    <div className="row justify-content-cener">
                                        <p className={classes.description}>Our services are ideal for concrete batch plant operations of all sizes and volume, including mobile plants.</p>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className="col col-operations">
                                    <div className="row">
                                        <img className="sample_img" src={RC} />
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="w-100 industry_heading">Asphalt Plants</div>
                                    </div>
                                    <div className="row justify-content-cener">
                                        <p className={classes.description}>Our specialized lanes allow us to supply asphalt plants with required sand material.</p>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className="col col-operations">
                                    <div className="row">
                                        <img className="sample_img" src={LandDev} />
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="w-100 industry_heading">Land Development</div>
                                    </div>
                                    <div className="row justify-content-cener">
                                        <p className={classes.description}>Limestone products available to create gravel roads, gravel pads or drainage solutions.</p>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className="col col-operations">
                                    <div className="row">
                                        <img className="sample_img" src={Landscaping} />
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="w-100 industry_heading">Commercial Landscaping</div>
                                    </div>
                                    <div className="row justify-content-cener">
                                        <p className={classes.description}>Order bulk specialized materials such as Bull Rock, decorative gravel, Blackstar, and more.</p>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className="col col-operations">
                                    <div className="row">
                                        <img className="sample_img" src={Precast} />
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="w-100 industry_heading">Concrete Precasting</div>
                                    </div>
                                    <div className="row justify-content-cener">
                                        <p className={classes.description}>We can source materials to meet project specifications required for concrete precast operations.</p>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className="col col-operations">
                                    <div className="row">
                                        <img className="sample_img" src={Other} />
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="w-100 industry_heading">And more!</div>
                                    </div>
                                    <div className="row justify-content-cener">
                                        <p className={classes.description}>If you need rock, we will deliver.  We are here to cater all of your bulk aggregate needs.</p>
                                    </div>
                                </div>
                            </GridItem>              
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}
