import React from "react";
import { Component } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//css
import "./LocationSection.css";

// core components
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem.js";
import InfoArea from "../material-kit-react-main/InfoArea/InfoArea.js";

//icons
import Pin from "@material-ui/icons/PinDropRounded";
import Clock from '@material-ui/icons/AccessTimeRounded';

//Google Maps API
//import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/productStyle";
const useStyles = makeStyles(styles);

class LocationSection extends Component {
    static displayName = LocationSection.name;
    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.section}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <InfoArea
                            title="Located in Houston, Texas"
                            description="Stop by and see us at our office in The Woodlands open Monday through Friday."
                            icon={Pin}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                </GridContainer>
                <br/>
                <div className="row w-100 ml-1 mr-1">
                    <iframe className="bei_iframe"
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBkrl_hWugaPrDvjKUgSGqKYyIzzPkFSGA&q=Buendia Enterprises Inc Texas" //width="100%" height="500" frameborder="0" style="border:0"
                    >
                    </iframe>
                </div>
                <br />
                <div className="">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <InfoArea
                                title="Business Hours"
                                description={
                                    <div>
                                        <div className="row">
                                            <div className="col bei_secondary_heading font-weight-bolder">Monday - Friday</div>
                                        </div>
                                        <div className="row">
                                            <div className="col font-weight-bold">9:00 AM - 5:00 PM CT</div>
                                        </div>
                                        <div className="row">
                                            <div className="col bei_secondary_heading font-weight-bolder">Saturday - Sunday</div>
                                        </div>
                                        <div className="row">
                                            <div className="col font-weight-bold">CLOSED</div>
                                        </div>
                                    </div>
                                }
                                icon={Clock}
                                iconColor="primary"
                                vertical
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

export default () => {
    const classes = useStyles();
    return (
        <LocationSection classes={classes} />
    )
}
