import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Footer from "../material-kit-react-main/Footer/Footer";
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem";
import Button from "../material-kit-react-main/CustomButtons/Button";
import Parallax from "../material-kit-react-main/Parallax/Parallax";

import styles from "../../assets/jss/material-kit-react/views/landingPage";

// Sections for this page
import WorkSectionFTL from "./WorkSectionFTL.js";

import '../Layout.css';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { faTags } from '@fortawesome/free-solid-svg-icons';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function BEI3PL(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (
        <div>
            <Parallax filter className="set_bg_3pl">
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem className="set_ta_center" xs={12} sm={12} md={6}>
                            <h1 className={classes.title}>Your 3PL Solution</h1>
                            <br />
                            <h3 className={classes.subtitle}>Take your business to new heights.</h3>
                            <br />
                            <br />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem className="set_ta_center" xs={12} sm={12} md={6}>
                            <Button
                                color="primary"
                                size="lg"
                                href="/freight/FTL/about"
                            >
                                <FontAwesomeIcon size="lg" icon={faTruckMoving} />
                                <b>FTL</b>
                            </Button>
                            <Button
                                color="primary"
                                size="lg"
                                href="/freight/LTL/about"
                            >
                                <FontAwesomeIcon size="lg" icon={faTruckLoading} />
                                <b>LTL</b>
                            </Button>
                            <Button
                                color="primary"
                                size="lg"
                                href="#"
                            >
                                <FontAwesomeIcon size="lg" icon={faTags} />
                                <b>Quote</b>
                            </Button>
                            <Button
                                color="info"
                                size="lg"
                                href="https://carrierrate.globaltranz.com/PublicTrack/PublicTrack/"
                            >
                                <FontAwesomeIcon size="lg" icon={faLocationArrow} />
                                <b>Tracking</b>
                            </Button>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                   
                </div>
            </div>
        </div>
    );
}
