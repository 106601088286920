import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Footer from "../material-kit-react-main/Footer/Footer";
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem";
import Button from "../material-kit-react-main/CustomButtons/Button";
import Parallax from "../material-kit-react-main/Parallax/Parallax";

import styles from "../../assets/jss/material-kit-react/views/landingPage";

// Sections for this page
import WorkSectionLTL from "./WorkSectionLTL.js";

import '../Layout.css';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import About from "./AboutLTL";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LTL(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Parallax filter className="set_bg_ltl">
        <div className={classes.container}>
          <GridContainer>
            <GridItem className="set_ta_center" xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Less-than Truck Load (LTL)</h1>
              <br />
                <h3 className={classes.subtitle}>The ideal solution for any business.</h3>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
            <About />
            <hr/>
            <WorkSectionLTL />
        </div>
      </div>
    </div>
  );
}
