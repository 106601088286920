import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "./material-kit-react-main/Grid/GridContainer";
import GridItem from "./material-kit-react-main/Grid/GridItem";
import Parallax from "./material-kit-react-main/Parallax/Parallax";

import styles from "../assets/jss/material-kit-react/views/landingPage";

// Sections for this page
import ProductList from "./Sections/ProductList.js";

import './Layout.css';

//icons

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPageInv(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (
        <div>
            <Parallax filter className="set_bg">
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem className="set_ta_center" xs={12} sm={12} md={6}>
                            <h1 className={classes.title}>Available Materials</h1>
                            <br />
                            <h3 className={classes.subtitle}>Find exactly what your commercial operation needs.</h3>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <ProductList />
                </div>
            </div>
        </div>
    );
}
