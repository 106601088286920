import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Speed from "@material-ui/icons/SpeedRounded";
import Money from "@material-ui/icons/MonetizationOnOutlined";
import Flex from "@material-ui/icons/GroupOutlined";
import Handling from "@material-ui/icons/TransformOutlined";

//images
import RM from '../../assets/img/readymix.jpg';
import Landscaping from '../../assets/img/landscaping.jpg';
import Precast from '../../assets/img/precast.jpg';
import RC from '../../assets/img/road construction.jpg';
import LandDev from '../../assets/img/gravelpad (2).jpg';
import Other from '../../assets/img/dump (2).jpg';


// core components
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem";
import InfoArea from "../material-kit-react-main/InfoArea/InfoArea.js";
import Parallax from "../material-kit-react-main/Parallax/Parallax";

import styles from "../../assets/jss/material-kit-react/views/landingPage";

// Sections for this page

import '../Layout.css';
import './Enddumps.css'

//icons

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function EndDumps(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (
        <div>
            <Parallax filter className="set_bg_enddumps">
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem className="set_ta_center" xs={12} sm={12} md={6}>
                            <h1 className={classes.title}>Drive with Us!</h1>
                            <br />
                            <h3 className={classes.subtitle}>Join our team of Owner Operators.</h3>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className="main_container">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h2 className="main_title">For more information, view our job posts!</h2>
                            </GridItem>
                            <GridItem className="paddbot" xs={12} sm={12} md={12}>
                                <p className={classes.description}>We source and deliver material in the Houston, San Antonio, and Austin markets.  We deliver to landscapers, concrete plants, and more. Find the lane that works best for you! 
                            </p>
                            </GridItem>
                            <GridItem className="paddbot" xs={12} sm={12} md={12}>
                                <p className={classes.description}><a href="/contact">Contact us</a> today or apply online at the links below.</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className="col col-operations">
                                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6903026771839512576" height="570" width="504" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className="col col-operations">
                                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbuendiaenterprises%2Fposts%2F1299879473844973&show_text=true&width=500" width="500" height="527" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}
