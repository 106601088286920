import React, { Component } from 'react';

import { makeStyles } from "@material-ui/core/styles";

import '../About.css';


// @material-ui/icons
import Money from "@material-ui/icons/MonetizationOnOutlined";
import Security from "@material-ui/icons/SecurityOutlined";
import Service from "@material-ui/icons/GroupOutlined";
import Tracking from "@material-ui/icons/TimelineRounded";


// core components
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem.js";
import InfoArea from "../material-kit-react-main/InfoArea/InfoArea.js";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/productStyle";
const useStyles = makeStyles(styles);

export default function About() {
    const classes = useStyles();
        return (
            <div className={classes.section}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>What is LTL?</h2>
                    </GridItem>             
                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.description}>Less than truckload freight shipping (LTL) is used for the transportation of
                        small freight or when freight doesn’t require the use of an entire trailer. This shipping method can be used when freight weighs between 150 and 15,000 pounds.
                        When shipping LTL, the shipper pays for the portion of a standard truck trailer their freight occupies, while other shippers and their shipments fill the unoccupied space.
                        </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>Why LTL?</h2>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <InfoArea
                            title="Reduces costs"
                            description="When booking an LTL shipment, you only pay for the portion of the trailer used. The rest of the cost is covered by the other occupants of the trailer’s space."
                            icon={Money}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <InfoArea
                            title="Increases security"
                            description="Most LTL shipments are packaged onto pallets before being loaded onto a truck. One well-packaged pallet has a better chance of remaining secure than shipments with multiple smaller handling units."
                            icon={Security}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <InfoArea
                            title="Customized Service"
                            description="When shipping via LTL, you gain access to special services like liftgates and inside pickup and delivery."
                            icon={Service}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <InfoArea
                            title="Tracking"
                            description="LTL carriers offer tracking capabilities through the bill of lading number, PRO number, PO number, shipment reference number and pick up date range, to name a few."
                            icon={Tracking}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>Our LTL Network</h2>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.description}>Powered by GlobalTranz</p>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="fedex_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="ups_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="dhl_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="saia_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="pittoh_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="lso_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="laser_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="ontrack_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="yrc_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="usps_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="old_img"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <div className="dicom_img"></div>
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <br/>
                        <br/>
                        <b><p className={classes.description}>More than 120+ LTL partners</p></b>
                    </GridItem>
                </GridContainer>
            </div>
        );
}