import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import { GlobalFooter } from './components/GlobalFooter';
import { Contact } from './components/Contact';
import { FFQuote } from './components/Quotes/FFQuote';
import { AggQuote } from './components/Quotes/AggQuote';
import { Inventory } from './components/Aggregates/Inventory';
import  FTL  from './components/Freight/FTL';
import LTL from './components/Freight/LTL';
import Operations from './components/Aggregates/Operations';
import BEI3PL from './components/Freight/BEI3PL';
import { FSC } from './components/Resources/FSC/FSC';
import EndDumps from './components/Carriers/EndDumps';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/freightforwarding/quote' component={FFQuote} />
            <Route path='/aggregates/quote' component={AggQuote} />
            <Route path='/aggregates/inventory' component={Inventory} />
            <Route path='/aggregates/info' component={Operations} />
            <Route path='/aggregates/drivers' component={Contact} />
            <Route path='/carriers/end-dumps' component={EndDumps} />
            <Route path='/contact' component={Contact} />
            <Route path='/freight/FTL/about' component={FTL}/>
            <Route path='/freight/LTL/about' component={LTL} />
            <Route path='/freight/home' component={BEI3PL} />
            <Route path='/freight/carriers' component={Contact} />
            <Route path='/clients/fuelsurcharge' component={FSC} />
            <GlobalFooter/>
        </Layout>
    );
  }
}
