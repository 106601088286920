import React, { Component } from 'react';

export class AggQuote extends Component {
    static displayName = AggQuote.name;

    render() {
        return (
            <div>
                hello this is an aggregates quote request
            </div>
        );
    }
}
