import React, { Component } from 'react';
import { NavMenuHead } from './NavMenuHead';
import { NavMenuMain } from './NavMenuMain';

import './Layout.css';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <NavMenuHead />
        <NavMenuMain />
        <div className="main_bei">
          {this.props.children}
        </div>
      </div>
    );
  }
}
