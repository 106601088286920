import React, { Component } from 'react';
import './Resources/FSC/fsc.css';

export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
    this.state = { EIArates: [], BEIrates: [], loading: true };
  }

  componentDidMount() {
    this.populateTables();
  }

  static renderBeiTable(BEIrates, EIArates) {
      return (
          <div className="row">
              <div className="col">
                  <table className='table table-striped text-white' aria-labelledby="tabelLabel">
                      <thead>
                          <tr>
                              <th colSpan='2'>BEI FSC Rate p/mi</th>
                          </tr>
                          <tr>
                              <th>PADD3 Price</th>
                              <th>FSC $/mi</th>
                          </tr>
                      </thead>
                      <tbody className={EIArates[0].Price}>
                          {BEIrates.map(fscRate =>
                              <tr className={'d' + (((fscRate.PADD3_Hi - EIArates[0].Price) < 0.099 && (fscRate.PADD3_Hi - EIArates[0].Price)>0)?('y'):('n'))} key={fscRate.PADD3_Lo}>
                                  <td>$ {fscRate.PADD3_Lo}-{fscRate.PADD3_Hi}</td>
                                  <td>$ {fscRate.Rate}</td>
                              </tr>
                          )}
                      </tbody>
                  </table>
                </div>
              <div className="col">
                  <table className='table table-striped text-white' aria-labelledby="tabelLabel">
                      <thead>
                          <tr>
                              <th colSpan='2'>EIA PADD3 Region Price</th>
                          </tr>
                          <tr>
                              <th>Date</th>
                              <th>Avg Price</th>
                          </tr>
                      </thead>
                      <tbody>
                          {EIArates.map((eiarate, i) =>
                              <tr className={'r'+i} key={eiarate.Date}>
                                  <td>{eiarate.Date.substring(0,10)}</td>
                                  <td>$ {eiarate.Price.toFixed(3)}<sup>9</sup></td>
                              </tr>
                          )}
                      </tbody>
                  </table>
              </div>
          </div>
    );
  }

  render() {
      let contents = this.state.loading
          ? <p className="text-white w-100"><em>Loading...</em></p>
          : FetchData.renderBeiTable(this.state.BEIrates, this.state.EIArates);

    return (
      <div>
        {contents}
      </div>
    );
    }

  async populateTables() {
      const response = await fetch('clients/fuel_surcharge');
      const data = await response.json();
      this.setState({ EIArates: JSON.parse(data.eia), BEIrates: JSON.parse(data.bei), loading: false });
    }

}
