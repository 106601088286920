import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';


import { Link } from 'react-router-dom';
import './NavMenuHead.css';


export class NavMenuHead extends Component {
  static displayName = NavMenuHead.name;

  constructor (props) {
    super(props);
  }

  render () {
    return (
        <header>
        <Navbar className="navbar-expand-sm ng-white border-bottom box-shadow mb-3 bei-navbar-main p-0 " light>
          <Container>
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                    <NavLink tag={Link} className="text-light bei-navbar-main-text" to="/"><FontAwesomeIcon icon={faHome} /> Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light bei-navbar-main-text" to="/"><FontAwesomeIcon icon={faGlobe} /> English</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to={{ pathname:"https://carrierrate.globaltranz.com/PublicTrack/PublicTrack/" }} target="_blank" className="text-light bg-primary bei-navbar-main-text"><FontAwesomeIcon icon={faLocationArrow} /> Tracking</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light bei-navbar-main-text" to="/contact">About / Contact</NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
