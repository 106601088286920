import React, { Component } from 'react';

export class FFQuote extends Component {
    static displayName = FFQuote.name;

    render() {
        return (
            <div>
                hello this is a freight forwarding quote
            </div>
        );
    }
}
