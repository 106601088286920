import React, { Component } from 'react';
import { FetchData } from '../../FetchData.js';

export class FSC extends Component {
    static displayName = FSC.name;


    render() {
        return (
            <div>
                <div className="container col w-95 justify-content-center">
                    <div className='row w-100 justify-content-center text-white m-0'>
                        <h2>Fuel Surcharge Rates</h2>
                    </div>
                    <div className='row w-100 justify-content-center m-0'>
                        <span class="text-danger text-center"><b>RATES APPLY TO SAND &amp; GRAVEL SALE PRICING ONLY.</b></span>
                    </div>
                    <div className='row w-100 justify-content-center text-white'>
                        <span className='text-center'><span className="font-weight-bolder">*</span> <span className="text-success font-weight-bolder">GREEN</span> are the current rates. Updated every Monday at 17:00 ET.</span>
                    </div>
                    <div className='row w-100 justify-content-center text-white text-small pt-3'>Source:<span className="m-1"></span><a target="_blank" href="https://www.eia.gov/dnav/pet/pet_pri_gnd_dcus_r30_w.htm">EIA.gov Gulf Coast Region</a></div>
                </div>
                <div className='container justify-content-center pt-5'><FetchData /></div>
            </div>
        );
    }
}
