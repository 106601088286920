/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { NavLink } from 'reactstrap';
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import GridContainer from "../../../components/material-kit-react-main/Grid/GridContainer";
import GridItem from "../../../components/material-kit-react-main/Grid/GridItem";


// Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

// core components
import CustomDropdown from "../../../components/material-kit-react-main/CustomDropdown/CustomDropdown";

import styles from "../../../assets/jss/material-kit-react/components/headerLinksStyle";
import '../Header/CustomHeaderLinks.css';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
    return (
      <GridContainer>
            <GridItem xs={12} md={9}>
                <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                        <CustomDropdown
                            noLiPadding
                            buttonText="AGGREGATES"
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent",
                            }}
                            //buttonIcon={Apps}
                            dropdownList={[
                                <a
                                    href="/aggregates/info"
                                    className={classes.dropdownLink}
                                >
                                    What we Do
                                </a>,
                                <a
                                    href="/contact"
                                    className={classes.dropdownLink}
                                >
                                    Get a Quote
                                </a>,
                                <a
                                    href="/aggregates/inventory"
                                    className={classes.dropdownLink}
                                >
                                    Product List
                                </a>,
                                <a
                                    href="https://www.1488rocks.com"
                                    target="_blank"
                                    className={classes.dropdownLink}
                                >
                                    Superior Landscaping<sup> <FontAwesomeIcon icon={faExternalLinkAlt} /></sup>
                                </a>,
                            ]}
                        />
                    </ListItem>  
                    <ListItem className={classes.listItem}>
                        <CustomDropdown
                            noLiPadding
                            buttonText="SHIP FREIGHT"
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent",
                            }}
                            //buttonIcon={Apps}
                            dropdownList={[
                                <a
                                    href="/freight/home"
                                    className={classes.dropdownLink}
                                >
                                    Your 3PL
                                </a>,
                                <a
                                    href="https://quote.beisynergy.com/freight"
                                    className={classes.dropdownLink}
                                >
                                    Get a Quote
                                </a>,
                                <a
                                    href="https://carrierrate.globaltranz.com/PublicTrack/PublicTrack/"
                                    target="_blank"
                                    className={classes.dropdownLink}
                                >
                                    Track Shipment<sup> <FontAwesomeIcon icon={faExternalLinkAlt} /></sup>
                                </a>,
                                <a
                                    href="/freight/FTL/about"
                                    className={classes.dropdownLink}
                                >
                                    Full Truck Load
                                </a>,
                                <a
                                    href="/freight/LTL/about"
                                    className={classes.dropdownLink}
                                >
                                    Less Than Truck Load
                                </a>,
                            ]}
                        />
                    </ListItem>  
                    <ListItem className={classes.listItem}>
                        <CustomDropdown
                            noLiPadding
                            buttonText="CARRIERS"
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent",
                            }}
                            //buttonIcon={Apps}
                            dropdownList={[
                                <a
                                    href="/carriers/end-dumps"
                                    className={classes.dropdownLink}
                                >
                                    End-Dumps
                                </a>,
                                <a
                                    href="/freight/carriers"
                                    className={classes.dropdownLink}
                                >
                                    General Freight
                                </a>,
                            ]}
                        />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <CustomDropdown
                            noLiPadding
                            buttonText="RESOURCES"
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent",
                            }}
                            //buttonIcon={Apps}
                            dropdownList={[
                                <a
                                    href="/contact"
                                    className={classes.dropdownLink}
                                >
                                    About Us
                                </a>,
                                <a
                                    href="/clients/fuelsurcharge"
                                    className={classes.dropdownLink}
                                >
                                    Fuel Surcharge Reference
                                </a>,
                            ]}
                        />
                    </ListItem>                  
                </List>
            </GridItem>
        </GridContainer>
  );
}
