import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Footer from "./material-kit-react-main/Footer/Footer";
import GridContainer from "./material-kit-react-main/Grid/GridContainer";
import GridItem from "./material-kit-react-main/Grid/GridItem";
import Button from "./material-kit-react-main/CustomButtons/Button";
import Parallax from "./material-kit-react-main/Parallax/Parallax";

import styles from "../assets/jss/material-kit-react/views/landingPage";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import WorkSection from "./Sections/WorkSection.js";
import LocationSection from "./Sections/LocationSection";

import './Layout.css';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import About from "../components/About";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Parallax filter className="set_bg">
        <div className={classes.container}>
          <GridContainer>
            <GridItem className="set_ta_center" xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Connect with Us</h1>
              <br />
              <Button
                color="primary"
                size="lg"
                href="https://www.facebook.com/buendiaenterprises/"
                target="_blank"
              >
                <FontAwesomeIcon size="lg" icon={faFacebook} />
              </Button>
              <Button
                color="primary"
                size="lg"
                href="https://www.linkedin.com/company/buendia-enterprises-inc/"
                target="_blank"
              >
                <FontAwesomeIcon size="lg" icon={faLinkedin} />
              </Button>
              <Button
                color="primary"
                size="lg"
                href="https://www.google.com/search?q=buendia+enterrprises+google+review&rlz=1C1CHBF_enUS925US925&oq=buendia+enterrprises+google+review&aqs=chrome..69i57j46i13i175i199.5074j0j7&sourceid=chrome&ie=UTF-8#lrd=0x86472ffccca5608b:0x2a93a56f1ba7aadb,1,,,"
                target="_blank"
              >
                <FontAwesomeIcon size="lg" icon={faGoogle} />
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
                  <About />
                  <hr/>
            <ProductSection />
            <hr/>
            <LocationSection />
            <hr/>
            <WorkSection />
        </div>
      </div>
    </div>
  );
}
