import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Inventory from "@material-ui/icons/LocalShippingRounded";
import Test from "@material-ui/icons/Assessment";

//images
import LS38 from '../../assets/img/products/0.375 Limestone.jpg';
import LS1 from '../../assets/img/products/1 Limestone (Heartland).jpg';
import LS35 from '../../assets/img/products/3x5 Limestone.jpg';
import LS57 from '../../assets/img/products/57 Stone (A.C.).jpg';
import LS67 from '../../assets/img/products/67 Stone.jpg';
import GR1 from '../../assets/img/products/1 Gravel (Tx Agg).jpg'
import GRRR from '../../assets/img/products/Rainbow.jpg'
import GRBR from '../../assets/img/products/3x5 Bull Rock.jpg'
import GRPG from '../../assets/img/products/Pea Gravel.jpg'
import SMS from '../../assets/img/products/Masonary Sand.jpg'
import SCS from '../../assets/img/products/Construction Sand.jpg'
import OGR3 from '../../assets/img/products/GR 3 0.625.jpg'
import OCB from '../../assets/img/products/Commercial Base.jpg'
import OFP from '../../assets/img/products/1.5-1 Fairland Pink.jpg'
import OSCR from '../../assets/img/products/screenings.jpg'

// core components
import GridContainer from "../material-kit-react-main/Grid/GridContainer";
import GridItem from "../material-kit-react-main/Grid/GridItem.js";
import InfoArea from "../material-kit-react-main/InfoArea/InfoArea.js";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/productStyle";
//css
import './ProductList.css';

const useStyles = makeStyles(styles);

export default function ProductList() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                    <InfoArea className="product_desc"
                        title="You need it - we've got it."
                        description="Our inventory consists of material from suppliers all across the HOU-AUS-DAL region.  Ask our sales department about additional products!"
                        icon={Inventory}
                        iconColor="primary"
                        vertical
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <InfoArea className="product_desc"
                        title="Material Research"
                        description="Ask our team today about sourcing options, color variations, and verified product specifications."
                        icon={Test}
                        iconColor="primary"
                        vertical
                    />
                </GridItem>
            </GridContainer>
            <hr/>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <div className="section_heading">LIMESTONE</div>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={LS38}/>
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">3/8" Limestone</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className=" w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={LS1} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">1" Limestone</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className="w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={LS35} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">3x5 Bull Rock (Limestone)</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className="w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={LS57} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">#57</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className="w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={LS67} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">#67</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className="w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
            <hr/>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <div className="section_heading">GRAVEL</div>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={GR1} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">1" Gravel</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className=" w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={GRRR} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">Rainbow Rock</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className=" w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={GRPG} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">Pea Gravel</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className=" w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={GRBR} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">3x5 Bull Rock (River)</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className=" w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
            <hr />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <div className="section_heading">SAND</div>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={SCS} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">Construction Sand</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className=" w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={SMS} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">Masonry Sand</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className="w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
            <hr />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <div className="section_heading">OTHER</div>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={OGR3} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">GR 3 Blackstar</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className=" w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={OCB} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">Commercial Base</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className="w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={OFP} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">Fairland Pink (Saledo Rose)</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className="w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className="col">
                            <div className="row">
                                <img className="product_img" src={OSCR} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="w-100 product_name">Screenings</div>
                            </div>
                            <div className="row justify-content-cener">
                                <div className="w-100 product_desc"></div>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
