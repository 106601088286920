import React, { Component } from 'react';
import LandingPage from './LandingPage'

//css
import './Contact.css';


export class Contact extends Component {
    static displayName = Contact.name;

    render() {
        return (
            <LandingPage className="bei_landingpage"/>
        );
    }
}
