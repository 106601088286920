import React, { Component } from 'react';

import { makeStyles } from "@material-ui/core/styles";

import './About.css';

// core components
import GridContainer from "./material-kit-react-main/Grid/GridContainer";
import GridItem from "./material-kit-react-main/Grid/GridItem.js";
import InfoArea from "./material-kit-react-main/InfoArea/InfoArea.js";

import styles from "../assets/jss/material-kit-react/views/landingPageSections/productStyle";
const useStyles = makeStyles(styles);

export default function About() {
    const classes = useStyles();
        return (
            <div className={classes.section}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>About BEI</h2>
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.description}>We are a privately owned company based in Texas.</p>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <div className="bei_img_esp"></div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <div className="bei_img_usa"></div>
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <h5 className={classes.title}>Aggregates Broker</h5>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.description}>We provide sourcing and delivery of aggregate materials in bulk such as Sand, Gravel, and Limestone to other independently owned companies in the Texas-Triangle region. We sum the parts by sourcing Supply and Logistics, negotiate the best rates on your behalf, and simply offer the benefits of economies of scale.</p>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.description}>We finance and deliver the products to our clients to create a cost-effective and reliable solution. Any product we offer to our clients is of superior quality by marketplace standards.</p>
                    </GridItem>


                    <GridItem xs={12} sm={12} md={12}>
                        <h5 className={classes.title}>Freight Forwarding</h5>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.description}>Buendia Enterprises is also a 3rd Party Logistics (3PL) company. </p>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.description}>From one to six pallets, we specialize in LTL Freight shipping for all of your needs. This is the most economical way to move smaller shipments and to ensure your smaller cargo arrives on time. This is also one of the most competitive over-the-road options we have, and the reason why our customers rely on us as their go-to provider for LTL shipments!</p>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.description}>For shipments that require more space, our experienced team is ready to assist. We can serve as your dedicated Full Truck provider and book all of your shipments on the hard to cover lane.</p>
                    </GridItem>

                </GridContainer>
            </div>
        );
}