import React, { Component } from 'react';
import GridContainer from "../components/material-kit-react-main/Grid/GridContainer";
import GridItem from "../components/material-kit-react-main/Grid/GridItem";
import Footer from "../components/material-kit-react-main/Footer/Footer";

//css
import './GlobalFooter.css';
//Bootstrap - html
import '../assets/bootstrap/dist/css/bootstrap.css';

export class GlobalFooter extends Component {
    static displayName = GlobalFooter.name;
    render() {
        return (
            <GridContainer>
              <GridItem><div className="bg-pattern"></div></GridItem>
              <GridItem><div className="bg-pattern"></div></GridItem>
              <GridItem><div className="bg-pattern"></div></GridItem>
              <GridItem><div><Footer whiteFont></Footer></div></GridItem>
            </GridContainer>
        );
    }
}
